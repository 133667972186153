interface INavItem {
  label: string;
  link: string;
}

interface IHeaderNavItem {
  id: number;
  label: string;
  link: string;
}

const nav: INavItem[] = [{ label: "Category", link: "/category" }];

export const helpNav: INavItem[] = [
  { label: "Delivery Information", link: "/delivery-info" },
  { label: "Contact us", link: "/contact" },
  { label: "Returns & Refunds", link: "/returns-refunds" },
  { label: "Privacy Notice", link: "/privacy-notice" },
  { label: "Shopping FAQs", link: "/shopping-faqs" },
];

export const categoryNav: INavItem[] = [
  { label: "Sofa (22)", link: "/category/sofa/product" },
  { label: "Coffee Table (22)", link: "/category/coffee-tables/product" },
  { label: "Bed Frames (99)", link: "/category/bed-frames/product" },
  { label: "Mattresses (40)", link: "/category/mattresses/product" },
  { label: "Dining Table (29)", link: "/category/dining-table/product" },
  { label: "Wardrobes (16)", link: "/category/wardrobes/product" },
  { label: "Study Desk (12)", link: "/category/study-desk/product" },
  { label: "Washing Machine (7)", link: "/category/washing-machine/product" },
  { label: "Small items (16)", link: "/category/small-items" },
];

export const headerNav: IHeaderNavItem[] = [
  { id: 4, label: "Lounge ", link: "/category/living-and-dining-room" },
  { id: 8, label: "Bedroom", link: "/category/bedroom-and-mattress" },
  { id: 10, label: "Outdoor ", link: "/category/outdoor-chairs-and-lounges" },
  { id: 13, label: "Kitchen", link: "/category/dining-and-kitchen" },
  { id: 18, label: "Hallway", link: "/category/entry-hallway" },
  { id: 20, label: "Decor", link: "/category/home-decor" },
  { id: 25, label: "Appliance", link: "/category/appliance" },
  { id: 32, label: "Office", link: "/category/home-office" },
  { id: 41, label: "Package", link: "/category/package" },
  { id: 47, label: "Accessories", link: "/category/small-items" },
  { id: 65, label: "Clearance", link: "/category/sample-product" },
];

export default nav;
